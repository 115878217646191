
import { defineComponent } from 'vue';
import CavuButton from '../cavu-button/cavu-button.vue';
import IconShare from '@white-label-icon/icon-share';
import IconEnvelope from '@white-label-icon/icon-envelope';
import IconPrinter from '@white-label-icon/icon-printer';
import { trackAction } from '@white-label-helper/helper-tracking';

export default defineComponent({
  name: 'BTicketShareButton',

  components: {
    CavuButton,
  },

  props: {
    showEmail: {
      type: Boolean,
      default: true,
    },
    shouldAlignLeft: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      menuOpened: false,
    };
  },

  computed: {
    IconShare() {
      return IconShare;
    },

    IconEnvelope() {
      return IconEnvelope;
    },

    IconPrinter() {
      return IconPrinter;
    },
  },

  methods: {
    toggleMenu() {
      trackAction('Share Button', { toggleMenu: {
        menuOpened: !this.menuOpened,
      },
      });
      this.menuOpened = !this.menuOpened;
    },
    closeMenu() {
      this.menuOpened = false;
      trackAction('Share Button', { toggleMenu: {
        menuOpened: 'false',
      }
      });
    },
  },
});
