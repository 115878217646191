import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1d21f53f = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _6310dba4 = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _6b177764 = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _7521ed96 = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _52c7659c = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _15619730 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _480e769a = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _ce770786 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _332b20d1 = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _69f7c4fd = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _0935276b = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _3b15aa39 = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _9af1b026 = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _7eb947e2 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _49d7c73a = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _1d9df048 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _4bb9f419 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _1d21f53f,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _6310dba4,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _6b177764,
    name: "callback"
  }, {
    path: "/downloads",
    component: _7521ed96,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _52c7659c,
    name: "guests-details"
  }, {
    path: "/login",
    component: _15619730,
    name: "login"
  }, {
    path: "/maintenance",
    component: _480e769a,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _ce770786,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _332b20d1,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _69f7c4fd,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _0935276b,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _3b15aa39,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _9af1b026,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _7eb947e2,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _49d7c73a,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _1d9df048,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _4bb9f419,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
