
// Packages
import { defineComponent } from 'vue';

// Types
import type VueI18n from 'vue-i18n/types';
import type { MetaInfo } from 'vue-meta';

// Helpers
import { getAppVariable } from '@white-label-helper/get-app-variable';

// Constants
import { NAMED_ROUTES } from '@white-label-configuration/constants';

export default defineComponent({
  name: 'NuxtError',

  components: {
    ErrorPage: () => import('@white-label-frontend/error'),
  },

  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: 400,
      }),
    },
  },

  head(): MetaInfo {
    return {
      title: this.createTabTitle,
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      script: [
        {
          json: this.$partnerConfig as { [key: string]: any },
          type: 'application/json',
          vmid: 'partner-channel-config',
        },
      ]
    };
  },

  computed: {
    getRouteName(): number {
      return this.error.statusCode;
    },

    getPageName(): VueI18n.TranslateResult {
      return this.$t(`pages.titles.${this.getRouteName}`);
    },

    createTabTitle(): string {
      return `${this.getPageName} | ${getAppVariable('partner_name')}`;
    },
  },

  created() {
    this.mountedCheckerHelper();
  },

  methods: {
    goBack(): void {
      this.$router.push(NAMED_ROUTES.home);
    },
  },
});
